import React from 'react'
import TourDescriptionSection from 'components/TourPage/TourDescription'
import TourThemeSection from 'components/TourPage/TourThemeSection'
import { Theme, TourneePage } from 'shared/types'

interface TourPage {
  themes: Theme[]
  tourContent: TourneePage
}

const TourPage = ({ themes, tourContent }: TourPage): JSX.Element => {
  return (
    <div className="main tour-page">
      <TourDescriptionSection tourContent={tourContent} />
      <TourThemeSection themes={themes} googleForm={tourContent.googleForm} />
    </div>
  )
}

export default TourPage
