import React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { TourneePage } from 'shared/types'

interface TourDescriptionSection {
  tourContent: TourneePage
}

const TourDescriptionSection = ({ tourContent }: TourDescriptionSection): JSX.Element => {
  return (
    <div className="section text-center top-of-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <h2 className="title">{tourContent.title}</h2>
            <h5 className="description">{tourContent.description}</h5>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <a
            className="mr-1 btn btn-link"
            title="Remplir le formulaire"
            href={tourContent.googleForm}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button type="button" color="primary">
              <FormattedMessage id="tour_btn_form" />
            </Button>
          </a>
        </Row>
      </Container>
    </div>
  )
}

export default TourDescriptionSection
