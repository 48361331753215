import Footer from 'components/Footer'
import Header from 'components/Header'
import PageHelmet from 'components/PageHelmet'
import ScrollToTop from 'components/ScrollToTop'
import TourPage from 'components/TourPage'
import React from 'react'
import { ToastContainer } from 'react-toastify'
import { graphql } from 'gatsby'
import { baseUrl, genericdescription, genericTitle, homeImage } from 'shared/constants'
import { TOUR_PAGE } from 'shared/routes'
import { ContentfulTheme, Location, Theme, TourPageContent } from 'shared/types'
import { mapToThemes, mapToTourContentFields, mapToGeneralPageFields } from 'services'

interface Tour {
  location: Location
  data: TourPageContent
}

const Tour = ({ location, data }: Tour): JSX.Element => {
  const content = mapToGeneralPageFields(data.contentfulGeneralPageData)
  const themes: Theme[] = data.allContentfulTheme.nodes.map((node: ContentfulTheme) => mapToThemes(node))
  const tourContent = mapToTourContentFields(data.contentfulTourneePage)
  return (
    <>
      <PageHelmet
        title={genericTitle}
        description={genericdescription}
        url={`${baseUrl}${TOUR_PAGE}`}
        image={homeImage}
      />
      <ScrollToTop location={location} />
      <ToastContainer />
      <Header location={location} contributionUrl={content.contributionUrl} merchandiseUrl={content.merchandiseUrl} />
      <TourPage themes={themes} tourContent={tourContent} />
      <Footer />
    </>
  )
}

export const query = graphql`
  query ThemeList($locale: String) {
    allContentfulTheme(filter: { node_locale: { eq: $locale } }) {
      nodes {
        image {
          fixed(width: 1920) {
            src
          }
        }
        description {
          raw
        }
        contentful_id
        title
      }
    }
    contentfulTourneePage(node_locale: { eq: $locale }) {
      contentful_id
      googleForm
      title
      description {
        raw
      }
    }
    contentfulGeneralPageData {
      contentful_id
      contributionUrl
      merchandiseUrl
    }
  }
`

export default Tour
