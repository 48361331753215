import React from 'react'
import { CardImg, Col, Container, Row } from 'reactstrap'

interface TourTheme {
  image: string
  title: string
  description: React.ReactNode
  isReverse: boolean
}

const normalDisplay = (image: string, title: string, description: React.ReactNode, isMobile: boolean): JSX.Element => {
  return (
    <Container>
      <Row>
        <Col md="12" lg="6">
          <CardImg className="img-theme hover-jump" src={image} alt="" />
        </Col>
        <Col>
          {!isMobile && <h3 className="title mt-0">{title}</h3>}
          {description}
        </Col>
      </Row>
    </Container>
  )
}

const reverseDisplay = (image: string, title: string, description: React.ReactNode, isMobile: boolean): JSX.Element => {
  return (
    <Container>
      <Row>
        <Col>
          {!isMobile && <h3 className="title mt-0">{title}</h3>}
          {description}
        </Col>
        <Col md="12" lg="6">
          <CardImg className="img-theme hover-jump" src={image} alt="" />
        </Col>
      </Row>
    </Container>
  )
}

const TourTheme = ({ image, title, description, isReverse }: TourTheme): JSX.Element => {
  let isMobile = false
  if (typeof window !== 'undefined') {
    isMobile = window.innerWidth < 991
  }
  return (
    <div className="section text-center theme-section">
      {isMobile || isReverse
        ? normalDisplay(image, title, description, isMobile)
        : reverseDisplay(image, title, description, isMobile)}
    </div>
  )
}

export default TourTheme
