import React from 'react'
import { Button } from 'reactstrap'
import { Theme } from 'shared/types'
import { FormattedMessage } from 'gatsby-plugin-intl'
import TourTheme from './TourTheme'

interface TourThemeSection {
  themes: Theme[]
  googleForm: string
}

const TourThemeSection = ({ themes, googleForm }: TourThemeSection): JSX.Element => {
  return (
    <div className="section text-center pt-0">
      <h2 className="title">
        <FormattedMessage id="tour_theme_title" />
      </h2>
      {themes.map((theme: Theme, index: number) => (
        <TourTheme
          key={theme.id}
          image={theme.image}
          title={theme.title}
          description={theme.description}
          isReverse={index % 2 === 0}
        />
      ))}
      <a
        className="mr-1 btn btn-link"
        title="Remplir le formulaire"
        href={googleForm}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Button type="button" color="primary">
          <FormattedMessage id="tour_btn_form" />
        </Button>
      </a>
    </div>
  )
}

export default TourThemeSection
